.Loading-aa{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.careerDetails-a{
  padding-top: 40px !important;
}
.careers-tag {
  font-size: 12px;
  color: #000;
}
.defolt {
  margin: 0px !important;
}
.CCCC{
  margin-left: 0px !important;
  padding-left: 25px;
}


.job-main {
  background: #e5942b;
  color: #fff;
  padding: 25px 10px;
  font-size: 24px;
  margin-bottom: 20px;
}
.box-career {
  padding: 15px 10px;
}
.keyRes{
  /* padding-right: 80px; */
}
.techReq{
  padding-left: 40px;
}


.apply-now-bt {
  font-size: 15px;
  color: #fff;
  background-color: #e5942b;
  border-radius: 20px;
  padding: 2px 15px;
  box-shadow: 0px 0px 5px #000;
  border: none;
  transition: 0.6s ease-in-out;
  margin: 20px 0 20px 30px;
}
.apply-now-bt:hover {
  font-size: 15px;
  color: #fff;
  background-color: #fcac44;
  border-radius: 20px;
  padding: 2px 15px;
  box-shadow: 0px 1px 5px #000;
  border: none;
  transition: 0.4s ease-in-out;
}
