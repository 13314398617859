.blogDetails-a a{
    color: #3366cc;
    cursor: pointer; 
}
.blogDetails-a a:hover{
    text-decoration: underline;
}

.th-bd{
    font-size: .8rem;
  }

.blogCardCategory-bd {
    border: 1px solid #c5d4f9;
    background-color: #eff2f5;
    cursor: pointer;
    padding: 5px 20px;
    color: #1e429f;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: small;
  }



@media (max-width:767px) {
  .blog-details-right{
    margin-top: 20px;
  }
}







  @media (max-width: 991px) {
    .blogCardCategory-bd {
      border: 1px solid #c5d4f9;
      background-color: #eff2f5;
      padding: 0px 2px;
      color: #1e429f;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      font-size: x-small;
    }

    .th-bd{
        display: flex ;
        flex-direction: column;
        gap: 10px;
      }
  }



  /* links */

.blogSocialLinks-bd{
    display: flex;
    align-items: center;
  justify-content: start;
  gap: 10px;
}


.blogSocialLinks-bd a{
  color: #3366cc;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3366cc;
  border-radius: 100%;
  padding: 5px;
}

.blogSocialLinks-bd a:hover{
  border: 1px solid #97b4ed;
}


  
 


  