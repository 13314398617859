.error-aa {
  color: red;
  font-size: 1rem;
}

.ssssaaaa {
  display: flex;
  justify-content: space-between;
}

.jobReq-ax {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  transition: -webkit-line-clamp .6s ease;
}

.jobReq-ax:hover {
  -webkit-line-clamp: unset; /* Remove the line clamp to show full text */
  white-space: normal;
}

.detailModalClose {
  display: flex;
  justify-content: end;
}
.detailModalCloseBtn {
  cursor: pointer;
}

.detailModalMain {
  padding: 6px;
  max-width: 500px;
  padding: 15px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border: 1px solid black;
  border-radius: 4px;
}
