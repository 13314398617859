.navbar-aa{
    position: fixed;
    top: 0px;
    z-index: 100;
    width: 100%;
}

.router-aa{
    margin-top: 110px;
}

@media only screen and (max-width: 768px) {
    .router-aa{
      margin-top: 95px;
    }
  }

@media only screen and (max-width: 425px) {
    .router-aa{
      margin-top: 84px;
    }
  }

  