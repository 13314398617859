/* .blogMain{
    height: 450px;
} */

.blogTitle-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
}

.blogContent-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
    transition: -webkit-line-clamp .6s ease;
    height: 60px;
}
#readMore-a{
    cursor: pointer;
}

.blogDate0{
  display: flex;
  justify-content: end;
}

.categoryWrap-b{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BlogDate-b{
  min-width: max-content;
  
}

.BlogDate-b i {
  border: 1px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px; 
  text-align: center;
  line-height: 19px;
  margin-right: 5px;
  font-size: .8rem;
}

@media (max-width: 768px) {
  .BlogDate-b{
    font-size: x-small;
  }
}

@media (max-width:768px) {
  .blogDate0{
    
    justify-content: start;
  }
}




.blogImg-a{
    aspect-ratio: 3/2;
}

.blogCardCategory{
    border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  padding: 2px 10px;
  cursor: pointer;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

/* clone */
.blogHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }

  .blogSearchForm {
    width: 295px;
    color: silver;
    border: 1px solid silver;
  }

@media (max-width: 575px) {
    .blogHeading {
      flex-direction: column;
      align-items: start;
    }
    .blogSearchForm {
      width: 100%;
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1399px) {
    .blogSearchForm {
      width: 360px;
    }
  }
  
  @media (min-width: 1400px) {
    .blogSearchForm {
      width: 415px;
    }
  }

  .form-control:focus {
    outline: none I !important;
    box-shadow: none !important;
    border-color: none;
  }


  /* right */

  .blog-right-main {
    margin-bottom: 20px;
    border-radius: 5px;
  }

  
.blog-right {
  overflow: auto;
  border: 1px solid rgba(14, 29, 52, 0.15);
  padding: 10px;
  background-color: #f8f9fa;
}

@media (min-width:1024px) and (max-width:1199px) {
  .blog-right {
    height: 603px;
  }
}
@media (min-width:1199px) and (max-width:1399px) {
  .blog-right {
    height: 690px;
  }
}

@media (min-width:1399px) {
  .blog-right {
    height: 765px;
  }
}

.PopularBlogWrap {
  overflow-y: auto;
}

.PopularBlogCard {
  cursor: pointer;
}

.th-a{
  font-size: .8rem;
}


.blogCardCategory {
  border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  cursor: pointer;
  padding: 5px 20px;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

@media (max-width: 575px) {
  .blogCardCategory {
    border: 1px solid #c5d4f9;
    background-color: #eff2f5;
    padding: 0px 2px;
    color: #1e429f;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: x-small;
  }
}

@media (max-width:575px) {
  .th-a{
    display: flex ;
    flex-direction: column;
    gap: 10px;
  }
}


.blogCardCategory {
  border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  cursor: pointer;
  padding: 5px 20px;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

@media (max-width: 768px) {
  .blogCardCategory {
    border: 1px solid #c5d4f9;
    background-color: #eff2f5;
    padding: 0px 2px;
    color: #1e429f;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: x-small;
  }
}

@media (max-width:575px) {
  .th-a{
    display: flex ;
    flex-direction: column;
    gap: 10px;
  }
}


#categorySpan {
  font-size: 0.7rem;
  color: gray;
}


/* card */


.card {
  flex-direction: row !important;
}

.card.card.bg-light-subtle .card-title {
  color: dimgrey;
}

.card-text{
  color: #202020;
}

.card img {
  max-width: 50%;
  /* max-height: 25%; */
  margin: auto;
  padding: 0.5em;
  border-radius: 0.7em;
  object-fit: contain;
}
.card-body {
  display: flex;
  justify-content: space-between;
}

.text-section {
  max-width: 100%;
}

.card-text {
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0 !important;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

#categorySpan {
  font-size: 0.7rem;
}

@media screen and (max-width: 475px) {
  .card {
    font-size: 0.9em;
  }
}

/* category popular blog */

.blogCategories {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 10px;
}

.blogCategory {
  border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  padding: 2px 20px;
  cursor: pointer;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

.blogCategory:hover {
  background-color: #f5faff;
}


/* links */

.blogSocialLinks{
  
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}


.blogSocialLinks a{
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3366cc;
  border: 1px solid #3366cc;
  border-radius: 100%;
  padding: 5px;
}

.blogSocialLinks a:hover{
  border: 1px solid #97b4ed;
}

/* load more */


.LoadMoreBlog {
  background-color: #006776;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.LoadMoreBlog:hover {
  background-color: #006776;
}



/* empty */

.EmptyPopularData{
  padding: 10px;
  font-size: .8rem;
  color: #575555;
}

.emptyCategory{
  font-size: .7rem;
  color: #575555;
}