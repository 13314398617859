.language-switcher {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .switch-container {
    display: flex;
    align-items: center;
  }
  
  .switch-key {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 15px;
    margin: 0 10px; 
  }
  
  .switch-key input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .key {
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: background-color 0.4s;
  }
  
  .key::before {
    content: "";
    height: 20px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    transition: transform 0.4s;
  }
  
  input:checked + .key {
    background-color: #007bff;
  }
  
  input:checked + .key::before {
    transform: translateX(30px) translateY(-50%);
  }
  
  .label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-left: -6px;
  }
  

  
  .navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.language-switcher-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem; /* Adjust margin as needed */
  margin-right: 1rem;
  display: contents;
}

.nav-bar-a {
  position: inherit !important; /* Required to position language switcher absolutely */
}
