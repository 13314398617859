

.blogTitle-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
}

.blogContent-a{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-bottom: 0px !important;
    transition: -webkit-line-clamp .6s ease;
    height: 60px;
}
#readMore-a{
    cursor: pointer;
}


.pagination-a{
    /* border: 3px solid black; */
    /* padding-left: 70px; */
}

.blogImg-a{
    aspect-ratio: 3/2;
}

.blogCardCategory{
    border: 1px solid #c5d4f9;
  background-color: #eff2f5;
  padding: 2px 10px;
  cursor: pointer;
  color: #1e429f;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}

/* copyied */

/* search */

.blogHeadingSearch{
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
}

.input-groupSearch{
    display: flex;
}