@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Gelasio:ital,wght@0,400..700;1,400..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Gelasio:ital,wght@0,400..700;1,400..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body {
  padding: 0px;
  margin: 0 auto;
  font-size: 20px;
}

h1 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 56px;
}
h2 {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #343434;
}
h3 {
  font-family: "Nunito", sans-serif;
  font-weight: 600 !important;
  color: #e5942b !important;
  font-size: 20px !important;
  text-transform: capitalize;
}
h4 {
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  color: #006776;
}
h5 {
  font-family: "Roboto", sans-serif;
  font-size: 13px !important;
  color: #006776;
}
h6 {
  font-family: "Roboto", sans-serif;
}
p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #5b5b5b;
  font-weight: 400;
}
.active {
  font-family: "Roboto", sans-serif;
  color: #006776 !important;
  font-weight: 400;
}

span {
  color: #e5942b;
}

/*-----header----start------*/
.top-bar {
  background-color: #006776;
  color: #fff;
  padding: 1px 0 5px 0;
}
.top-left ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.top-left ul li {
  display: inline-block;
  font-size: 15px;
  padding-right: 7px;
}
.top-right ul {
  list-style: none;
  display: flex;
  justify-content: end;
  margin: 0px;
}
.top-right ul li {
  display: inline-block;
  padding: 0 4px;
}

.top-right ul li a i {
  color: #fff;
  border: solid 2px #fff;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  font-size: 14px;
}
.top-right ul li a i:hover {
  color: #c5c5c5;
  border: solid 2px #fff;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  font-size: 14px;
  transition: 0.6s ease-in;
}
.navbar-main {
  background: #fff;
}
.navbar-brand img {
  width: 200px;
}
.nav-link {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding: 0 50px;
  letter-spacing: 1px;
}
.nav-item :focus-visible {
  background-color: #006776;
  border-radius: 2px;
  transition: 0.6s ease-in;
}
.enquiry-bt {
  margin-top: -7px;
}
.enquiry-bt a {
  font-size: 16px;
  color: #fff;
  background-color: #e5942b;
  padding: 4px 15px;
  border-radius: 20px;
  text-decoration: none;
}
/*-----header----end--*/

/*--------------------------middle----------------------start-------------*/

/*------banner----start---*/
.carousel-inner {
  background: rgb(255, 252, 249);
  background: linear-gradient(
    160deg,
    rgba(255, 252, 249, 1) 0%,
    rgba(0, 103, 118, 1) 100%
  );
}
.carousel-item img {
  float: right;
}
.carousel-caption {
  position: absolute !important;
  right: 15% !important;
  bottom: 1.25rem !important;
  left: 1% !important;
  top: 8% !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  color: #fff !important;
  text-align: left !important;
}
.banner-about {
  width: 60%;
}
.banner-bt {
  margin-top: 30px;
  /* margin-left: 50px; */
  z-index: 5;
}
.banner-bt a {
  background: rgb(255, 252, 249);
  background: linear-gradient(
    160deg,
    rgba(255, 252, 249, 1) 0%,
    rgba(0, 103, 118, 1) 100%
  );
  outline: solid 2px #fff;
  padding: 8px 15px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
}
.banner-bt a:hover {
  background: rgb(255, 252, 249);
  background: linear-gradient(
    90deg,
    rgba(255, 252, 249, 1) 0%,
    rgba(0, 103, 118, 1) 100%
  );
  outline: solid 2px #fff;
  padding: 8px 15px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  outline: solid 1px #fff;
  border-radius: 4px;
  color: #006776;
}
/*------banner----end---*/

/*------counter------start------*/
.counter-main {
  background: #e5942b;
}
#counter {
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #e5942b;
  padding: 5px 0px;
  width: 100%;
}
#counter .item {
  padding: 5px 5px;
  margin: 5px 10px;
  text-align: left;
}
#counter .item .count {
  margin-bottom: 5px;
}
.count {
  color: #fff;
  font-weight: 700;
  font-size: 25px;
}
.text {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
}
.icon-color {
  color: #fff;
  font-size: 24px;
}
/*counter---end--*/

/*--about-us---start---*/

.about-us-main {
  padding: 70px 0 0px 0;
}

.about-us-main:nth-last-child(1) {
  padding-bottom: 20px;
}
.read-more-bt {
  padding-top: 40px;
}
.read-more-bt a {
  font-size: 16px;
  color: #e5942b;
  text-decoration: none;
  border: solid 2px #e5942b;
  border-radius: 25px;
  padding: 3px 15px;
}
.read-more-bt a:hover {
  font-size: 16px;
  color: #d27804;
  text-decoration: none;
  border: solid 2px #d27804;
  border-radius: 25px;
  padding: 3px 15px;
  box-shadow: 0px 0px 2px #d27804;
}
.about-content p {
  color: #5b5b5b;
}
/*--about-us---end---*/

/*--Services-We-Offer---start---*/
.services-main {
  padding: 70px 0 50px 0;
  background-color: #fffcf7;
}
.heading-main {
  text-align: center;
}
.heading-main h2 {
  text-align: center;
  text-transform: capitalize;
}
.heading-main p {
  margin: 0 auto;
  width: 50%;
  padding-bottom: 30px;
  color: #5b5b5b;
}

.services-box {
  height: 300px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #fff;
  outline: solid 2px #fff;
  margin-bottom: 25px;
}
.services-box:hover {
  color: #fff;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 0 10px #333434;
  outline: solid 2px #fff;
  margin-bottom: 25px;
  transition: 0.6s ease-in;
}
.services-box p {
  color: #5b5b5b;
}
.services-read-more-bt a {
  font-size: 14px;
  color: #e5942b;
  text-decoration: none;
  border: solid 1px #e5942b;
  border-radius: 25px;
  padding: 4px 10px;
}
.services-read-more-bt a:hover {
  font-size: 14px;
  color: #d27804;
  text-decoration: none;
  border: solid 1px #d27804;
  border-radius: 25px;
  padding: 2px 10px;
  box-shadow: 0px 0px 2px #d27804;
}
/*--Services-We-Offer---end--*/

/*---Technologies We Work-----start----*/
.technologies-main {
  padding: 70px 0 50px 0;
  background-color: #fff;
}
.technologies-box {
  text-align: center;
}
.technologies-box p {
  color: #006776;
}
/*---Technologies We Work-end----*/

/*---why-choose-us------start----*/
.why-choose-us-main {
  padding: 50px 0 30px 0;
  background: rgb(0, 103, 118);
  background: linear-gradient(
    42deg,
    rgba(0, 103, 118, 1) 0%,
    rgba(0, 192, 220, 1) 100%
  );
}
.why-choose-box h2,
p {
  color: #fff;
}
.why-content-box {
  background-color: #fff;
  border-radius: 2px 40px 2px 40px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.why-content-box:hover {
  background-color: #fff;
  border-radius: 40px 2px 40px 2px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  transition: 0.6s ease-in-out;
  cursor: pointer;
}
.idea-image {
  text-align: right;
}
.idea-image img {
  border-radius: 70px 3px 50px 3px;
}
.idea-image img:hover {
  border-radius: 3px 70px 3px 70px;
  transition: 0.6s ease-in-out;
}
/*---why-choose-us----end---*/

/*---working-process------start-----*/
.working-process-main {
  padding: 70px 0 50px 0;
  background-color: #fff;
}
/*---working-process----end---*/

/*---have-a-project------start-----*/
.have-a-project-main {
  padding: 80px 0px;
  background-image: url("../images/have-a-project.png");
  background-repeat: no-repeat;
  background-position: c;
  background-attachment: fixed;
  background-size: cover;
}
.have-a-project-cuntent {
  color: #fff;
  text-align: center;
}
.have-a-project-cuntent h2 {
  color: #fff;
}
.have-a-project-cuntent p {
  width: 70%;
  margin: 0 auto;
}
.Let-Schedule-bt {
  text-align: center;
  margin-top: 20px;
}
.Let-Schedule-bt a {
  color: #fff;
  text-decoration: none;
  padding: 6px 20px;
  font-size: 14px;
  background-color: #e5942b;
  border-radius: 25px;
  border: solid 1px #fff;
}
.Let-Schedule-bt a:hover {
  color: #fff;
  text-decoration: none;
  padding: 6px 20px;
  font-size: 14px;
  background-color: #d07e14;
  border-radius: 25px;
  border: solid 1px #fff;
  transition: 0.6s ease-in;
}

/*---have-a-project----end-----*/

/*---Client-Review-----start---*/
.ic-a {
  width: 20px;
}

.suugam {
  /* margin-top: 20px; */
  /* width: 130px; */
  width: 20%;
  text-align: center;
  margin: 0px auto;
}
.skymmd {
  /* width: 80px; */
  width: 15%;
  text-align: center;
  margin: 0px auto;
}
.spotrack {
  /* width: 180px; */
  width: 20%;
  text-align: center;
  margin: 0px auto;
}
.khome {
  /* width: 140px; */
  width: 25%;
  text-align: center;
  margin: 0px auto;
}
/*---Client-Review----end---*/
.client-review-main {
  padding: 70px 0 40px 0;
  background-color: #fff;
}
.details-box {
  /* border: 3px solid black; */

  margin: 20px 20px;
  background-color: #fff;
  padding: 20px 20px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 0 8px #cfcfcf;
  text-align: center !important;
  cursor: pointer;
  min-height: 350px;
  max-height: 350px;
  max-width: 100%;
}
.a2z {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.slick-slide img {
  display: inline-block;
}
.details-box h2 {
  font-size: 20px;
  text-transform: uppercase;
  transition: 0.6s all;
}
.details-box p {
  color: #5b5b5b;
  font-weight: 400;
  font-style: italic;
}

.slick-next::before,
.slick-prev::before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------pages-------work------start-------*/

.about-men-bg {
  background-image: url("../images/about-men-bg.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  position: relative;
}
.portfolio-us-main {
  background-image: url("../images/portfolio-bg.png");
}
.blog-us-main {
  background-image: url("../images/blog.png");
}
.careers-us-main {
  background-image: url("../images/careers-bg.png");
}
.privacy-us-main {
  background-image: url("../images/privacy-bg.png");
}
.terms-us-main {
  background-image: url("../images/terms-bg.png");
}
.contact-us-main {
  background-image: url("../images/contact-bg.png");
}
.about-men h2 {
  font-size: 35px;
  color: #fff;
  text-transform: uppercase;
}
.about-bottom-men {
  background-color: #efefef;
  padding: 10px 0;
}
.about-bottom h6 {
  font-size: 16px;
  margin: 0;
}
.about-bottom h6 label a {
  font-size: 16px;
  text-decoration: none;
  color: #02379d;
  padding-right: 6px;
}
.about-men {
  position: relative;
  z-index: 9;
}
.about-men-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0067769e;
  z-index: 0;
}
.portfolio-box {
  background-color: #f8f9fa;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}
.portfolio-text p {
  font-size: 14px;
  color: #000;
  text-align: center;
  padding-top: 10px;
}
.blog-men-text {
  background-color: #f8f9fa;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}
.blog-men-text img {
  width: 100%;
}
.blog-text {
  padding: 18px 15px 15px 15px;
}
.blog-name h6 {
  font-size: 12px;
}
.blog-text1 h6 {
  font-size: 13px;
  color: #202020;
  padding-top: 5px;
  text-transform: capitalize;
}
.blog-text1 p {
  font-size: 12px;
  color: #5b5b5b;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 8px;
  padding-top: 5px;
}
.blog-text1 a {
  font-size: 13px;
  color: #202020;
  text-transform: capitalize;
  text-decoration: none;
}
.blog-name h6 i {
  border: 1px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 19px;
  margin-right: 5px;
}
.navbar-main {
  border-bottom: 1px solid #ccc;
}
.blog-men-text {
  padding-bottom: 10px;
}
.blog-text2 {
  padding-top: 30px;
}
.blog-men-text-two {
  height: 100%;
}
.search-man {
  position: relative;
}
.search-btn {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-radius: 50px;
  padding: 10px 15px;
  font-size: 14px;
}
.search-man label i {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 12px;
  color: #9c9a9a;
}
.search-man-two label {
  font-size: 18px;
  color: #000000;
  width: 100%;
}
.categories-btn {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  padding: 10px 5px;
  font-size: 14px;
  margin-top: 5px;
}
.contact-box {
  background-color: #006776;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}
.contact-box i {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
}
.contact-box-text {
  line-height: 18px;
}
.contact-box-text h6 {
  font-size: 16px;
  color: #202020;
  margin: 0;
}
.contact-box-text label {
  font-size: 13px;
  color: #a0a0a0;
  margin: 0;
}
.input-btn {
  padding: 8px 15px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  color: #121212;
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
}
.input-btn1 {
  height: 200px;
}
.send-message-btn {
  margin-top: 20px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #006776;
  color: #fff;
  border: none;
  text-transform: capitalize;
  font-size: 16px;
}
.privacy-policy-text h6 {
  font-size: 20px;
}
.privacy-policy-text1 {
  padding-top: 10px;
}
.privacy-policy-text1 h6 {
  font-size: 16px;
}
.privacy-policy-text1 p {
  color: #545454;
  font-size: 13px;
  margin-left: 15px;
  margin-bottom: 5px;
}
.privacy-policy-text1 ul {
  margin-left: 15px;
  margin-bottom: 0;
}
.privacy-policy-text1 ul li {
  color: #545454;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 10px;
}
.blog-name-1 h6 {
  font-size: 18px;
  margin-bottom: 15px;
}
.blog-text3 h6 {
  font-size: 12px;
  margin: 0;
}
.blog-text3 p {
  font-size: 12px;
  color: #5b5b5b;
  margin-bottom: 5px;
}
.apply-now-btn {
  padding: 4px 20px;
  background-color: #006776;
  color: #fff;
  font-size: 13px;
  border: none;
  border-radius: 50px;
  text-transform: capitalize;
  margin-top: 10px;
  box-shadow: 0 2px 0px #e5942b;
}
.apply-now-btn:hover {
  box-shadow: none;
  transition: all 0.4s ease-in-out;
}
.form-resume {
  color: #8f8f8f;
  float: left;
  font-size: 14px;
  padding-bottom: 5px;
}

.services-men-bg {
  background-image: url("../images/services-men-bg.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  position: relative;
}
.services-bg {
  background-color: #fff;
}

.services-menu-left {
  background-color: #f8f9fa;
  height: 100%;
  border: solid 1px #b7b7b7;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tab-bt {
  border-radius: 4px;
  border: solid 1px #dcd8d8;
}
.services-menu-left ul li a {
  text-align: left;
  color: #000;
  text-decoration: none;
}
.services-menu-left ul li a:active {
  text-align: left;
  color: #fff;
  text-decoration: none;
  background-color: #000;
}
.navs .active {
  background-color: #e5942c !important;
  color: #fff !important;
}
.ux-ui-main {
  text-align: left;
}
.ux-ui-main img {
  border-radius: 10px;
}
.ux-ui-main h3 {
  text-transform: uppercase;
}
.ux-ui-main p {
  font-size: 14px;
  color: #5b5b5b;
  font-weight: 400;
}
.tags {
  font-size: 14px;
}

.client-men-bg {
  background-image: url("../images/client-banner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  position: relative;
}
.help-men-bg {
  background-image: url("../images/help-banner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  position: relative;
}
.fqa-men-bg {
  background-image: url("../images/fqa-banner.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 80px 0;
  position: relative;
}
.accordion-button {
  background-color: #f8f9fa;
  font-size: 18px;
  font-weight: 600;
  color: #232323;
  border: solid 1px #e8e8e8;
  border-radius: 5px;
}
.accordion-item {
  margin: 15px 0px;
  border-radius: 5px;
}
.accordion-body p {
  color: #5b5b5b;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none;
}

.services-menu-left {
  background-color: #f8f9fa;
  border: solid 1px #b7b7b7;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 100%;
}
.services-menu-left ul li a {
  text-align: left;
  color: #000;
  text-decoration: none;
  width: 270px;
  font-size: 14px;
  padding: 5px 15px;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 3px;
}
.services-text1 {
  padding-top: 10px;
}
.services-text1 ul {
  margin-left: 15px;
  margin-bottom: 0;
}
.services-text1 ul li {
  color: #545454;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 10px;
}
.ux-ui-main p {
  color: #545454;
  font-size: 13px;
  line-height: 16px;
}
.ux-ui-main img {
  width: 100%;
}
.sample-main {
  margin: 10px;
  padding-bottom: 10px;
}
.sample-main img {
  border: solid 1px #a7a7a7;
  padding: 5px;
  border-radius: 3px;
}
.sample-main a {
  font-size: 14px;
  color: #5b5b5b;
  font-weight: 400;
}

.tab-pane:not(.active) {
  display: none;
}
/*pages--work---end--*/

/*--------------------middle-------------end---------------------*/

/*---------------footer---------start------------------*/
.footer {
  padding: 70px 0 50px 0px;
  background-color: #006776;
}

.footer-right {
  float: left;
}

.footer-right ul {
  padding-left: 1px;
}
.footer-tital {
  color: #fff;
  padding-bottom: 10px;
}
.footer-info p {
  font-size: 14px;
  font-weight: 400;
}

.footer-links ol,
ul {
  padding-left: 20px;
  color: #fff;
}
.footer-links ul li a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-size: 13px;
}
.footer-links ul li a:hover {
  color: #c1c1c1;
  font-size: 14px;
  text-decoration: none;
  transition: 0.6s ease-in;
}

.get-touch ul {
  display: inline-block;
  padding: 0px;
}
.get-touch ul li {
  list-style: none;
  font-size: 14px;
  padding-bottom: 10px;
}

.copyright-main {
  color: #fff;
  background-color: #e5942b;
  text-align: center;
  font-size: 15px;
  padding: 15px 0;
}
.copyright-main a {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
/*--footer----end----*/
/* 
#btn-back-to-top {
  position: fixed;
  bottom: 25px;
  right: 20px;
  display: none;
  background-color: #e5942b;
  border: solid 1px #fff;
  font-size: 16px;
}
#btn-back-to-top:hover {
  position: fixed;
  bottom: 25px;
  right: 20px;
  display: none;
  background-color: #d5942b;
  border: solid 1px #fff;
  font-size: 16px;
}


.float {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 80px;
  right: 23px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  font-size: 28px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 100;
} */
.float {
  width: 45px;
  height: 45px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  font-size: 28px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 100;
} 

.float:hover{
  color: #fff;
}

#btn-back-to-top {
  width: 0px;
  height: 0px;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: #e5942b;
  border: solid 1px #fff;
  font-size: 16px;
  transition: all .8s ease-in-out;
}

#btn-back-to-top:hover {
  border: solid 1px #fff;
}



.FooterIcons{
  position: fixed;
  z-index: 100;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1px 20px !important;
}

/*==========================================----working--process----=====================*/

#timeline .timeline-item:after,
#timeline .timeline-item:before {
  content: "";
  clear: both;
  display: block;
  width: 100%;
}
#timeline {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin: 2%;
  padding: 0;
  position: relative;
  width: 90%;
}
#timeline:after {
  clear: both;
  content: "";
  display: table;
  width: 100%;
}
#timeline .timeline-item {
  -webkit-animation: fadein 1s linear 1 forwards;
  -moz-animation: fadein 1s linear 1 forwards;
  -ms-animation: fadein 1s linear 1 forwards;
  animation: fadein 1s linear 1 forwards;
  display: block;
  float: left;
  margin: 0;
  opacity: 0;
  position: relative;
  width: 30%;
}
#timeline .timeline-item:after {
  border-top: 4px solid #00b9e1;
  padding-bottom: 60%;
  margin-left: 50%;
}

#timeline .aaa:after {
  border-top: 4px solid #00b9e1;
  padding-bottom: 60%;
  margin-left: 50%;
  margin-top: -4px;
}

#timeline .timeline-item::before {
  content: "";
  border-bottom: 10px solid transparent;
  border-left: 10px solid #f5b90f;
  border-top: 10px solid transparent;
  height: 10px;
  left: 100%;
  position: absolute;
  top: -7px;
  width: 15px;
}
#timeline .aaa::before {
  content: "";
  border-bottom: 10px solid transparent;
  border-left: 10px solid #f5b90f;
  border-top: 10px solid transparent;
  height: 10px;
  left: 100%;
  position: absolute;
  top: -11px;
  width: 15px;
}

#timeline .bbb::before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid #00b9e1;
  content: "";
  height: 15px;
  left: 50%;
  position: absolute;
  top: -5px;
  width: 15px;
}

#timeline .timeline-item.two {
  animation-delay: 0.5s;
}
#timeline .timeline-item.three {
  animation-delay: 1s;
}
#timeline .timeline-item.three:after {
  border: 4px solid #00b9e1;
  border-left: 0;
  border-radius: 0 50% 50% 0;
  margin-left: 50%;
}
#timeline .timeline-item.three:before {
  left: -webkit-calc(150% - 8px);
  left: -moz-calc(150% - 8px);
  left: -o-calc(150% - 8px);
  left: calc(150% - 8px);
  border-left: 7px solid transparent;
  border-top: 7px solid #f5b90f;
  border-right: 7px solid transparent;
  top: 50%;
}
#timeline .timeline-item.four {
  animation-delay: 1.5s;
  float: right;
  margin-right: 10%;
  margin-top: -3px;
}
#timeline .timeline-item.four:after {
  margin-left: -50%;
}
#timeline .timeline-item.four:before {
  border-left: 10px solid transparent;
  border-right: 10px solid #f5b90f;
  left: 0;
}
#timeline .timeline-item.five {
  animation-delay: 2s;
  float: right;
  margin-top: -3px;
}
#timeline .timeline-item.five:after {
  margin-left: -50%;
}
#timeline .timeline-item.five:before {
  border-left: 10px solid transparent;
  border-right: 10px solid #f5b90f;
  left: 0;
}
#timeline .timeline-item.six {
  animation-delay: 2.5s;
  float: right;
  margin-top: -3px;
}

#timeline .timeline-item.six::after {
  border: 4px solid #00b9e1;
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: rgb(0, 185, 225);
  border-right: 0;
  border-radius: 50% 0 0 50%;
  margin-left: 0%;
}

#timeline .timeline-item.six::before {
  left: 0px;
  margin-left: -6px;
  top: 50%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f5b90f;
}
#timeline .timeline-item.seven {
  animation-delay: 3s;
}
#timeline .timeline-item.eight {
  animation-delay: 3.5s;
}
#timeline .timeline-item.nine {
  animation-delay: 4s;
}
#timeline .timeline-item.nine:after {
  border: none;
}
#timeline .timeline-item.nine:before {
  border: none;
}
#timeline .timeline-content {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-top: 10%;
  position: absolute;
  text-align: justify;
  width: 100%;
}
#timeline .timeline-content h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 0 50%;
  line-height: 1.5rem;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
#timeline .timeline-content p {
  margin-left: 50%;
  padding-right: 10%;
  text-align: justify;
  width: 100%;
  color: #5b5b5b;
  font-weight: 400;
}
#timeline .timeline-content:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid #00b9e1;
  content: "";
  height: 15px;
  left: 50%;
  position: absolute;
  top: -5px;
  width: 15px;
}
#timeline .bbb::before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid #00b9e1;
  content: "";
  height: 15px;
  left: 50%;
  position: absolute;
  top: -10px;
  width: 15px;
}
#timeline button {
  background: #00b9e1;
  border: 0;
  color: #fff;
  display: block;
  margin-top: 10px;
  padding: 5px 15px;
}
#timeline button a {
  color: #fff;
  font-family: "Dosis", arial, tahoma, verdana;
  font-size: 1rem;
  text-decoration: none;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  #timeline {
    width: 95%;
  }
  #timeline .timeline-item.one,
  #timeline .timeline-item.two,
  #timeline .timeline-item.three,
  #timeline .timeline-item.four,
  #timeline .timeline-item.five,
  #timeline .timeline-item.seven,
  #timeline .timeline-item.eight,
  #timeline .timeline-item.nine {
    display: block;
    float: left;
    width: 100%;
  }
  #timeline .timeline-item.one:after,
  #timeline .timeline-item.two:after,
  #timeline .timeline-item.three:after,
  #timeline .timeline-item.four:after,
  #timeline .timeline-item.five:after,
  #timeline .timeline-item.seven:after,
  #timeline .timeline-item.eight:after,
  #timeline .timeline-item.nine:after {
    border: none;
    border-left: 3px solid #00b9e1;
    margin-left: 1px;
    padding-bottom: 40%;
  }
  #timeline .timeline-item.one:before,
  #timeline .timeline-item.two:before,
  #timeline .timeline-item.three:before,
  #timeline .timeline-item.four:before,
  #timeline .timeline-item.five:before,
  #timeline .timeline-item.seven:before,
  #timeline .timeline-item.eight:before,
  #timeline .timeline-item.nine:before {
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #f5b90f;
    height: 15px;
    left: -5px;
    position: absolute;
    top: 50%;
    width: 15px;
  }
  #timeline .timeline-item.one:last-child:after,
  #timeline .timeline-item.two:last-child:after,
  #timeline .timeline-item.three:last-child:after,
  #timeline .timeline-item.four:last-child:after,
  #timeline .timeline-item.five:last-child:after,
  #timeline .timeline-item.seven:last-child:after,
  #timeline .timeline-item.eight:last-child:after,
  #timeline .timeline-item.nine:last-child:after {
    border: none;
  }
  #timeline .timeline-item.one:last-child:before,
  #timeline .timeline-item.two:last-child:before,
  #timeline .timeline-item.three:last-child:before,
  #timeline .timeline-item.four:last-child:before,
  #timeline .timeline-item.five:last-child:before,
  #timeline .timeline-item.seven:last-child:before,
  #timeline .timeline-item.eight:last-child:before,
  #timeline .timeline-item.nine:last-child:before {
    border: none;
  }
  #timeline .timeline-item.six {
    width: 100%;
  }
  #timeline .timeline-item.six:after {
    border-right: 0;
    border-left: 0;
    margin-left: -20%;
    padding-bottom: 40%;
    width: 0%;
  }
  #timeline .timeline-item.six:before {
  }
  #timeline .timeline-content {
    width: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-right: 0;
    padding-top: 0;
    position: absolute;
    text-align: justify;
    z-index: 9;
  }
  #timeline .timeline-content h3 {
    margin: -9px 15px;
    font-size: 12px;
  }
  #timeline .timeline-content p {
    margin-left: 0;
    padding: 10px 0 0 15px;
    width: 100%;
    font-size: 11px;
  }
  #timeline .timeline-content:before {
    left: -5px;
    top: -5px;
  }
  #timeline .timeline-item.six::after {
    border: 0px solid #00b9e1;
    border-right-width: 3px;
    border-right-style: solid;
    border-right-color: rgb(0, 185, 225);
    /* border-right: 0px; */
    border-radius: 50% 0 0 50%;
    margin-left: 0%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #timeline .timeline-content h3 {
    font-size: .7rem;
  }
  #timeline .timeline-content p {
    font-size: .51rem;
  }
}

/*#timeline .timeline-item::after {
  border-top: 3px solid #00B9E1;
  padding-bottom: 60%;
  margin-left: 50%;
}*/
/*========================================end ================*/

.carousel-control-next,
.carousel-control-prev {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 6% !important;
  padding: 0 !important;
  color: #fff !important;
  text-align: center !important;
  background: 0 0 !important;
  border: 0 !important;
  opacity: 0.5 !important;
  transition: opacity 0.15s ease !important;
}

/*---responsive----media------query-----start--------*/

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .top-left {
    display: none;
  }
  /* .kuchbhi {
    margin: 0px 0 8px 15px;
  } */

  .top-right {
    text-align: center;
  }
  .top-right ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0px;
  }
  .navbar-brand {
    width: 50%;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .slick-next,
  .slick-prev {
    display: none !important;
  }
  .navbar-nav {
    padding-top: 10px;
    padding-left: 20px !important;
    font-size: 14px;
  }
  .text {
    font-size: 10px;
    color: #fff;
    text-transform: capitalize;
  }
  .item {
    text-align: center !important;
    border: solid 1px #fff;
  }
  #counter .item {
    padding: 5px 5px;
    margin: 5px 1px;
    text-align: left;
  }

  .about-us-main,
  .services-main,
  .technologies-main,
  .working-process-main,
  .have-a-project-main,
  .footer {
    padding: 40px 0 30px 0;
  }

  .client-review-main {
    padding: 40px 20px 30px 0;
  }

  p {
    font-size: 12px;
  }
  .read-more-bt {
    padding-top: 10px;
  }
  .heading-image {
    display: none;
  }
  .heading-main p {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 15px;
    color: #5b5b5b;
  }
  .services-box {
    background-color: #fff;
    color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px #d1d1d4;
    outline: solid 2px #fff;
    margin-bottom: 30px;
    height: 255px;
  }
  .why-content-box img {
    width: 40%;
    margin: 0 auto;
  }
  .why-content-box {
    background-color: #fff;
    border-radius: 20px 20px 20px 20px;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    height: 125px;
  }
  .why-content-box:hover {
    background-color: #fff;
    border-radius: 40px 2px 40px 2px;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    transition: 0.6s ease-in-out;
    cursor: pointer;
  }
  .details-box img {
  }
  .details-box {
    margin: 20px 23px;
    padding: 10px 10px;
  }
  .footer-info p {
    font-size: 12px;
    font-weight: 400;
  }
  .footer-tital {
    padding: 10px 0 0 0;
  }
  .logo img {
    width: 60%;
  }
  .copyright-main {
    font-size: 13px;
    padding: 5px 0;
  }
  .footer-links ul li {
    font-size: 14px;
    transition: 0.6s ease-in;
    padding: 2px 0;
  }
  /* #btn-back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: none;
    background-color: #e5942b;
    border: solid 1px #fff;
    font-size: 12px;
  } */
/* 
  #btn-back-to-top:hover {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: none;
    background-color: #d5942b;
    border: solid 1px #fff;
    font-size: 12px;
  } */

  .nav-link {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    padding: 3px 20px;
    line-height: 12px;
  }
  .enquiry-bt {
    margin: 0 5px 5px 5px;
  }
  .enquiry-bt a {
    font-size: 14px;
    color: #fff;
    background-color: #e5942b;
    padding: 4px 15px;
    border-radius: 20px;
    text-decoration: none;
  }
  .about-men-bg {
    background-image: url("../images/about-men-bg.png");
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 30px 0;
    position: relative;
  }
  .about-men h2 {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
  }
  .about-bottom h6 label a {
    font-size: 12px;
    font-weight: normal;
  }
  .about-bottom h6 {
    font-size: 12px;
    font-weight: normal;
  }
  .about-content h4 {
    padding-top: 10px;
  }

  .services-menu-left {
    background-color: #f8f9fa;
    border: solid 1px #b7b7b7;
    border-radius: 10px;
    margin-bottom: 20px;
    height: inherit;
  }
  .services-menu-left ul li a {
    text-align: left;
    color: #000;
    text-decoration: none;
    width: 270px;
    font-size: 10px;
    padding: 2px 15px;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 1px;
  }
  .profile-right p {
    font-size: 12px;
  }
  .contact-men {
    margin-bottom: 10px;
  }
  .loc h6 {
    padding-top: 10px;
  }
  .contact-men-form {
    padding-top: 20px;
  }
  .accordion-button {
    font-size: 11px;
    font-weight: 500;
    padding: 5px;
  }
  .accordion-button.collapsed {
    padding: 5px;
  }
  .accordion-item {
    margin: 8px 0px;
    border-radius: 5px;
  }
  .privacy-policy-text h6 {
    font-size: 16px;
  }
  .have-a-project-cuntent p {
    width: 100%;
    margin: 0 auto;
  }
  .navbar {
    --bs-navbar-padding-x: none;
  }
  .sample {
    display: none;
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  /* .float {
    position: fixed;
    width: 30px;
    height: 30px;
    bottom: 60px;
    right: 15px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 20px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  } */
  .sample-main {
    display: none;
  }
  .spotrack {
    width: 30%;
    text-align: center;
    margin: 5px auto;
  }
  .khome,
  .suugam {
    width: 30%;
    text-align: center;
    margin: 5px auto;
  }
  .skymmd {
    width: 20%;
    text-align: center;
    margin: 5px auto;
  }
  .my-float {
    margin-top: 1px;
  }

  .aboutUs-a{
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* .top-left{
} */
  .kuchbhi {
    margin: 10px 0 10px 0;
  }
  .about-us-main,
  .services-main,
  .technologies-main,
  .working-process-main,
  .have-a-project-main,
  .client-review-main,
  .footer {
    padding: 40px 0 30px 0;
  }
  .top-left ul li {
    display: inline-block;
    font-size: 11px;
    padding-right: 7px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }
  .carousel-caption {
    width: 60%;
  }
  .carousel-caption h1 {
    font-size: 20px;
  }
  .carousel-caption h4 {
    font-size: 10px !important;
  }
  .carousel-item img {
    width: 40%;
  }
  .banner-about {
    width: 100%;
    font-size: 10px;
  }
  .banner-bt {
    margin-top: 10px;
  }
  .banner-bt a {
    background: rgb(255, 252, 249);
    background: linear-gradient(
      160deg,
      rgba(255, 252, 249, 1) 0%,
      rgba(0, 103, 118, 1) 100%
    );
    outline: solid 2px #fff;
    padding: 4px 9px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
  }
  .banner-bt a:hover {
    background: rgb(255, 252, 249);
    background: linear-gradient(
      90deg,
      rgba(255, 252, 249, 1) 0%,
      rgba(0, 103, 118, 1) 100%
    );
    outline: solid 2px #fff;
    padding: 4px 9px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
  }
  .item h3 {
    font-size: 12px;
  }
  .read-more-bt {
    padding-top: 10px;
  }
  .why-content-box img {
    width: 30%;
  }
  .why-content-box h5 {
    font-size: 10px;
  }
  .why-content-box {
    height: 95px;
    background-color: #fff;
    border-radius: 20px 20px 20px 20px;
    padding: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
  .why-content-box:hover {
    background-color: #fff;
    border-radius: 40px 2px 40px 2px;
    padding: 5px;
    margin-bottom: 20px;
    text-align: center;
    transition: 0.6s ease-in-out;
    cursor: pointer;
  }
  .services-menu-left {
    background-color: #f8f9fa;
    border: solid 1px #b7b7b7;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .services-menu-left ul li a {
    text-align: left;
    color: #000;
    text-decoration: none;
    width: 190px;
    font-size: 10px;
    padding: 2px 15px;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 1px;
  }
  .Location-repons {
    margin-top: 20px;
  }
  .banner-bt {
    margin-bottom: 20px;
  }
  .details-box {
    /* border: 3px solid black; */

    margin: 20px 20px;
    background-color: #fff;
    padding: 20px 20px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 0 8px #cfcfcf;
    text-align: center !important;
    cursor: pointer;
    min-height: 400px;
    max-height: 400px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }
  .carousel-caption {
    width: 60%;
  }
  .carousel-caption h1 {
    font-size: 28px;
  }
  .carousel-item img {
    width: 40%;
  }
  .banner-about {
    width: 100%;
    font-size: 14px;
  }
  .banner-bt {
    margin-top: 10px;
  }
  .banner-bt a {
    background: rgb(255, 252, 249);
    background: linear-gradient(
      160deg,
      rgba(255, 252, 249, 1) 0%,
      rgba(0, 103, 118, 1) 100%
    );
    outline: solid 2px #fff;
    padding: 6px 12px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
  }
  .banner-bt a:hover {
    background: rgb(255, 252, 249);
    background: linear-gradient(
      90deg,
      rgba(255, 252, 249, 1) 0%,
      rgba(0, 103, 118, 1) 100%
    );
    outline: solid 2px #fff;
    padding: 6px 12px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
  }
  .carousel-control-prev-icon {
    position: absolute;
    left: 10%;
  }
  .carousel-control-prev-icon {
    position: absolute;
    left: 10%;
  }
  .carousel-control-next-icon {
    position: absolute;
    right: 10%;
  }
  .services-menu-left {
    background-color: #f8f9fa;
    border: solid 1px #b7b7b7;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .services-menu-left ul li a {
    text-align: left;
    color: #000;
    text-decoration: none;
    width: 200px;
    font-size: 10px;
    padding: 2px 15px;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 1px;
  }
  .profile-right p {
    font-size: 12px;
  }
  #timeline .timeline-content p {
    margin-left: 50%;
    padding-right: 10%;
    text-align: justify;
    width: 100%;
    color: #5b5b5b;
    font-weight: 400;
    font-size: 11px;
  }
  .details-box {
    /* border: 3px solid black; */

    margin: 20px 20px;
    background-color: #fff;
    padding: 20px 20px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 0 8px #cfcfcf;
    text-align: center !important;
    cursor: pointer;
    max-height: 450px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .carousel-control-next,
  .carousel-control-prev {
  }
}

@media (min-width: 768px) {
  .girlImg-a {
    display: flex;
    align-items: center;
  }
  .aboutUsImg-a{
    display: flex;
    align-items: center;
  }
}

@media (max-width: 765px) {
  .girlImg-a {
    display: none;
  }
}


@media (min-width:768px) and (max-width:1399px) {
  .aboutImage-az{
    display:none ;
  }
  .aboutContent-az{
    width: 100% !important;
  }
}
/*---responsive---media----query----end----*/
/* hov */

.image-hover-text-container {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
  width: 550px;
}
.image-hover-image {
  display: block;
}
.image-hover-image img {
  border-radius: 10px;
  width: 100%;
}
.image-hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}
.image-hover-text:hover {
  opacity: 1;
}
.image-hover-text-bubble {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  right: 100%;
  height: 100%;
  text-align: center;
  background: rgba(218, 211, 211, 0.9);
  border: 1px solid #848484;
  border-radius: 10px;
  /* margin: 0 auto; */
  /* padding: 20% 8px 5px 8px; */
  overflow: hidden;
  font-size: 17px;
  text-align: center;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-hover-text .image-hover-text-title {
  font-size: 25px;
  display: block;
}
.washwooshImage{ 
  height: 500px;
  /* width: 634px; */
}
.HrmsImage{
  height: 500px;
  /* width: 500px; */
}

/* .sldf{
  border: 1px solid #ccc;
} */

@media (min-width:768px) and (max-width:1199px) {
  .image-hover-text-container {
    width: 460px;
  }

}

@media (max-width:992px) {
  .ProductWrap-a{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    /* align-items: center; */
  }

  .sldf{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-hover-text-container{
    width: 100%;
  }
}

@media (min-width:1399px) {
  .image-hover-text-container {
    width: 630px;
  }
}
